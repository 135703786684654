import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

export interface ModelError {
  code: string;
  reason: string;
}

const isApiErrorModel = (error: object) => 'code' in error && 'reason' in error;

export const apiErrorInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    catchError((response: HttpErrorResponse) => {
      if (isApiErrorModel(response)) {
        return throwError(() => response.error);
      } else {
        const error: ModelError = {
          code: response.status.toString(),
          reason: response.message
        };
        return throwError(() => error);
      }
    })
  );
};
