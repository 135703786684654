export const getFileIconClass = (mimeType: string | undefined) => {
  switch (mimeType) {
    case 'application/pdf':
      return 'pi pi-file-pdf';
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'pi pi-file-word';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'pi pi-file-excel';
    case 'image/jpeg':
      return 'pi pi-file-image';
    default:
      return 'pi pi-file';
  }
};
