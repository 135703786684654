import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Ripple } from 'primeng/ripple';
import { ButtonDirective } from 'primeng/button';

@Component({
  selector: 'lib-not-found-page',
  standalone: true,
  imports: [ButtonDirective, Ripple, RouterLink],
  templateUrl: './not-found-page.component.html',
  styleUrl: './not-found-page.component.css'
})
export class NotFoundPageComponent {
}
