<div class='surface-section px-4 py-8 md:px-6 lg:px-8'>
  <div class='flex flex-column lg:flex-row justify-content-center align-items-center gap-7'>
    <div class='text-center lg:text-right'>
      <div class='mt-6 mb-3 font-bold text-6xl text-900'>Are you lost?</div>
      <p class='text-700 text-3xl mt-0 mb-6'>Sorry, we couldn't find the page.</p>
      <button pButton type='button' routerLink='' [outlined]='true' label='Go back to home page'></button>
    </div>
    <div><img src='https://blocks.primeng.org/assets/images/blocks/feedback/404-rocket.png' alt='Image'
              class='w-full md:w-28rem'></div>
  </div>
</div>
