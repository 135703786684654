import { KeycloakEnvOptions } from '../../auth.provider';

export const getKeycloakOptions = (keycloakEnvOptions: KeycloakEnvOptions) => {
  return {
    config: {
      url: keycloakEnvOptions.URL,
      realm: keycloakEnvOptions.REALM,
      clientId: keycloakEnvOptions.CLIENT_ID
    },
    loadUserProfileAtStartUp: true,
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri:
        window.location.origin + '/silent-check-sso.html',
      checkLoginIframe: false,
      redirectUri: keycloakEnvOptions.REDIRECT_URI
    }
  };
};
