import * as i1 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Directive, Inject, Input, booleanAttribute, Component, ChangeDetectionStrategy, ViewEncapsulation, NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { UniqueComponentId } from 'primeng/utils';

/**
 * Badge Directive is directive usage of badge component.
 * @group Components
 */
function Badge_span_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 1);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r0.styleClass);
    i0.ɵɵproperty("ngClass", ctx_r0.containerClass())("ngStyle", ctx_r0.style);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.value);
  }
}
class BadgeDirective {
  document;
  el;
  renderer;
  /**
   * When specified, disables the component.
   * @group Props
   */
  disabled;
  /**
   * Size of the badge, valid options are "large" and "xlarge".
   * @group Props
   */
  badgeSize;
  /**
   * Size of the badge, valid options are "large" and "xlarge".
   * @group Props
   * @deprecated use badgeSize instead.
   */
  set size(value) {
    this._size = value;
    console.warn('size property is deprecated and will removed in v18, use badgeSize instead.');
  }
  get size() {
    return this._size;
  }
  _size;
  /**
   * Severity type of the badge.
   * @group Props
   */
  severity;
  /**
   * Value to display inside the badge.
   * @group Props
   */
  value;
  /**
   * Inline style of the element.
   * @group Props
   */
  badgeStyle;
  /**
   * Class of the element.
   * @group Props
   */
  badgeStyleClass;
  id;
  badgeEl;
  get activeElement() {
    return this.el.nativeElement.nodeName.indexOf('-') != -1 ? this.el.nativeElement.firstChild : this.el.nativeElement;
  }
  get canUpdateBadge() {
    return this.id && !this.disabled;
  }
  constructor(document, el, renderer) {
    this.document = document;
    this.el = el;
    this.renderer = renderer;
  }
  ngOnChanges({
    value,
    size,
    severity,
    disabled,
    badgeStyle,
    badgeStyleClass
  }) {
    if (disabled) {
      this.toggleDisableState();
    }
    if (!this.canUpdateBadge) {
      return;
    }
    if (severity) {
      this.setSeverity(severity.previousValue);
    }
    if (size) {
      this.setSizeClasses();
    }
    if (value) {
      this.setValue();
    }
    if (badgeStyle || badgeStyleClass) {
      this.applyStyles();
    }
  }
  ngAfterViewInit() {
    this.id = UniqueComponentId() + '_badge';
    this.renderBadgeContent();
  }
  setValue(element) {
    const badge = element ?? this.document.getElementById(this.id);
    if (!badge) {
      return;
    }
    if (this.value != null) {
      if (DomHandler.hasClass(badge, 'p-badge-dot')) {
        DomHandler.removeClass(badge, 'p-badge-dot');
      }
      if (this.value && String(this.value).length === 1) {
        DomHandler.addClass(badge, 'p-badge-no-gutter');
      } else {
        DomHandler.removeClass(badge, 'p-badge-no-gutter');
      }
    } else {
      if (!DomHandler.hasClass(badge, 'p-badge-dot')) {
        DomHandler.addClass(badge, 'p-badge-dot');
      }
      DomHandler.removeClass(badge, 'p-badge-no-gutter');
    }
    badge.innerHTML = '';
    const badgeValue = this.value != null ? String(this.value) : '';
    this.renderer.appendChild(badge, this.document.createTextNode(badgeValue));
  }
  setSizeClasses(element) {
    const badge = element ?? this.document.getElementById(this.id);
    if (!badge) {
      return;
    }
    if (this.badgeSize) {
      if (this.badgeSize === 'large') {
        DomHandler.addClass(badge, 'p-badge-lg');
        DomHandler.removeClass(badge, 'p-badge-xl');
      }
      if (this.badgeSize === 'xlarge') {
        DomHandler.addClass(badge, 'p-badge-xl');
        DomHandler.removeClass(badge, 'p-badge-lg');
      }
    } else if (this.size && !this.badgeSize) {
      if (this.size === 'large') {
        DomHandler.addClass(badge, 'p-badge-lg');
        DomHandler.removeClass(badge, 'p-badge-xl');
      }
      if (this.size === 'xlarge') {
        DomHandler.addClass(badge, 'p-badge-xl');
        DomHandler.removeClass(badge, 'p-badge-lg');
      }
    } else {
      DomHandler.removeClass(badge, 'p-badge-lg');
      DomHandler.removeClass(badge, 'p-badge-xl');
    }
  }
  renderBadgeContent() {
    if (this.disabled) {
      return null;
    }
    const el = this.activeElement;
    const badge = this.document.createElement('span');
    badge.id = this.id;
    badge.className = 'p-badge p-component';
    this.setSeverity(null, badge);
    this.setSizeClasses(badge);
    this.setValue(badge);
    DomHandler.addClass(el, 'p-overlay-badge');
    this.renderer.appendChild(el, badge);
    this.badgeEl = badge;
    this.applyStyles();
  }
  applyStyles() {
    if (this.badgeEl && this.badgeStyle && typeof this.badgeStyle === 'object') {
      for (const [key, value] of Object.entries(this.badgeStyle)) {
        this.renderer.setStyle(this.badgeEl, key, value);
      }
    }
    if (this.badgeEl && this.badgeStyleClass) {
      this.badgeEl.classList.add(...this.badgeStyleClass.split(' '));
    }
  }
  setSeverity(oldSeverity, element) {
    const badge = element ?? this.document.getElementById(this.id);
    if (!badge) {
      return;
    }
    if (this.severity) {
      DomHandler.addClass(badge, `p-badge-${this.severity}`);
    }
    if (oldSeverity) {
      DomHandler.removeClass(badge, `p-badge-${oldSeverity}`);
    }
  }
  toggleDisableState() {
    if (!this.id) {
      return;
    }
    if (this.disabled) {
      const badge = this.activeElement?.querySelector(`#${this.id}`);
      if (badge) {
        this.renderer.removeChild(this.activeElement, badge);
      }
    } else {
      this.renderBadgeContent();
    }
  }
  static ɵfac = function BadgeDirective_Factory(ɵt) {
    return new (ɵt || BadgeDirective)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: BadgeDirective,
    selectors: [["", "pBadge", ""]],
    hostAttrs: [1, "p-element"],
    inputs: {
      disabled: [0, "badgeDisabled", "disabled"],
      badgeSize: "badgeSize",
      size: "size",
      severity: "severity",
      value: "value",
      badgeStyle: "badgeStyle",
      badgeStyleClass: "badgeStyleClass"
    },
    features: [i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BadgeDirective, [{
    type: Directive,
    args: [{
      selector: '[pBadge]',
      host: {
        class: 'p-element'
      }
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }], {
    disabled: [{
      type: Input,
      args: ['badgeDisabled']
    }],
    badgeSize: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    severity: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    badgeStyle: [{
      type: Input
    }],
    badgeStyleClass: [{
      type: Input
    }]
  });
})();
/**
 * Badge is a small status indicator for another element.
 * @group Components
 */
class Badge {
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Size of the badge, valid options are "large" and "xlarge".
   * @group Props
   */
  badgeSize;
  /**
   * Severity type of the badge.
   * @group Props
   */
  severity;
  /**
   * Value to display inside the badge.
   * @group Props
   */
  value;
  /**
   * When specified, disables the component.
   * @group Props
   */
  badgeDisabled = false;
  /**
   * Size of the badge, valid options are "large" and "xlarge".
   * @group Props
   * @deprecated use badgeSize instead.
   */
  set size(value) {
    this._size = value;
    console.warn('size property is deprecated and will removed in v18, use badgeSize instead.');
  }
  get size() {
    return this._size;
  }
  _size;
  containerClass() {
    return {
      'p-badge p-component': true,
      'p-badge-no-gutter': this.value != undefined && String(this.value).length === 1,
      'p-badge-lg': this.badgeSize === 'large' || this.size === 'large',
      'p-badge-xl': this.badgeSize === 'xlarge' || this.size === 'xlarge',
      [`p-badge-${this.severity}`]: this.severity
    };
  }
  static ɵfac = function Badge_Factory(ɵt) {
    return new (ɵt || Badge)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: Badge,
    selectors: [["p-badge"]],
    hostAttrs: [1, "p-element"],
    inputs: {
      styleClass: "styleClass",
      style: "style",
      badgeSize: "badgeSize",
      severity: "severity",
      value: "value",
      badgeDisabled: [2, "badgeDisabled", "badgeDisabled", booleanAttribute],
      size: "size"
    },
    features: [i0.ɵɵInputTransformsFeature],
    decls: 1,
    vars: 1,
    consts: [[3, "ngClass", "class", "ngStyle", 4, "ngIf"], [3, "ngClass", "ngStyle"]],
    template: function Badge_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, Badge_span_0_Template, 2, 5, "span", 0);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", !ctx.badgeDisabled);
      }
    },
    dependencies: [i1.NgClass, i1.NgIf, i1.NgStyle],
    styles: ["@layer primeng{.p-badge{display:inline-block;border-radius:10px;text-align:center;padding:0 .5rem}.p-overlay-badge{position:relative}.p-overlay-badge .p-badge{position:absolute;top:0;right:0;transform:translate(50%,-50%);transform-origin:100% 0;margin:0}.p-badge-dot{width:.5rem;min-width:.5rem;height:.5rem;border-radius:50%;padding:0}.p-badge-no-gutter{padding:0;border-radius:50%}}\n"],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Badge, [{
    type: Component,
    args: [{
      selector: 'p-badge',
      template: ` <span *ngIf="!badgeDisabled" [ngClass]="containerClass()" [class]="styleClass" [ngStyle]="style">{{ value }}</span> `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: ["@layer primeng{.p-badge{display:inline-block;border-radius:10px;text-align:center;padding:0 .5rem}.p-overlay-badge{position:relative}.p-overlay-badge .p-badge{position:absolute;top:0;right:0;transform:translate(50%,-50%);transform-origin:100% 0;margin:0}.p-badge-dot{width:.5rem;min-width:.5rem;height:.5rem;border-radius:50%;padding:0}.p-badge-no-gutter{padding:0;border-radius:50%}}\n"]
    }]
  }], null, {
    styleClass: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    badgeSize: [{
      type: Input
    }],
    severity: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    badgeDisabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    size: [{
      type: Input
    }]
  });
})();
class BadgeModule {
  static ɵfac = function BadgeModule_Factory(ɵt) {
    return new (ɵt || BadgeModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: BadgeModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BadgeModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [Badge, BadgeDirective, SharedModule],
      declarations: [Badge, BadgeDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Badge, BadgeDirective, BadgeModule };
