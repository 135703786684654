import { KeycloakService } from 'keycloak-angular';
import { APP_INITIALIZER, EnvironmentProviders, Provider } from '@angular/core';
import { initializeKeycloak, KEYCLOAK_OPTIONS } from './integration/keycloak/keycloak.factory';
import { getKeycloakOptions } from './integration/keycloak/keycloak-options.factory';
import { AuthenticationService } from './service/authentication.service';

export type KeycloakEnvOptions = {
  URL: string
  REALM: string
  CLIENT_ID: string
  REDIRECT_URI: string
}

export const provideAuthentication: (keycloakEnvOptions: KeycloakEnvOptions) => (Provider | EnvironmentProviders)[] =
  (environment: KeycloakEnvOptions) => [
    { provide: KEYCLOAK_OPTIONS, useValue: getKeycloakOptions(environment) },
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    AuthenticationService
  ];
