import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';


export const authGuard: CanActivateFn = async (route, state) => {
  const authenticationService = inject(AuthenticationService);

  const isAuthenticated = authenticationService.isLoggedIn();

  if (!isAuthenticated) {
    await authenticationService.login({
      redirectUri: window.location.origin + state.url
    });
  } else {
    const userProfile = await authenticationService.getUserProfile();
    localStorage.setItem('fullName', `${userProfile.firstName} ${userProfile.lastName}`);
  }

  const { roles } = route.data;
  const userRoles = authenticationService.getUserRoles();

  return isAuthenticated && (roles ?? []).every((role: string) => userRoles.includes(role));
};
