import { createReducer, on } from '@ngrx/store';

import { KeycloakProfile } from 'keycloak-js';
import { setUser } from './user.action';

export interface UserState {
  user: KeycloakProfile | null;
}

export const initialState: UserState = {
  user: null
};

export const userReducer = createReducer(
  initialState,
  on(setUser, (state, { user }) => ({ ...state, user }))
);
