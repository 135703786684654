import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  standalone: true,
  name: 'filesize'
})
export class FileSizePipe implements PipeTransform {
  transform(bytes: number): string {
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  }
}
